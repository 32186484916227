import React from 'react';
import css from './Header.module.css';

const Header = props => {
  const { description, title } = props;
  return (
    <div className={css.Section}>
      <div className={css.container}>
        <div className={css.description}>{description}</div>
        <div className={css.title}>{title}</div>
      </div>
    </div>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
