import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ManageFavouritesPage/SET_INITIAL_STATE';

export const QUERY_LISTINGS_REQUEST = 'app/ManageFavouritesPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ManageFavouritesPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ManageFavouritesPage/QUERY_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userListings: [],
  queryListingsError: null,
};

export default function manageFavouritesPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListings: [],
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListings: payload.listings };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListings: [], queryListingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { },
});

export const queryListingsSuccess = listings => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const attachAuthorAndImagesToListings = (listings) => {
  if (listings?.data?.length) {
      const data = [];
      listings.data.map(l => {
          l.author = listings.included.find(item => item.type == "user" && item.id.uuid == l.relationships.author.data.id.uuid);
          l.images = l.relationships.images.data.map(imageData => {
              return listings.included.find(item => item.type === "image" && item.id.uuid === imageData.id.uuid);
          });
          data.push(l);
      });
      return data;
  };
  return [];
};

export const queryUserListings = (config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const user = getState().user.currentUser;
  const favouriteListing = user?.attributes?.profile?.publicData?.favoriteListings || [];

  return sdk.listings
    .query({
      ids: favouriteListing.join(","),
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      const listings = attachAuthorAndImagesToListings(response.data);
      dispatch(queryListingsSuccess(listings));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  dispatch(setInitialState());

  return Promise.all([
    dispatch(queryUserListings(config)),
  ]);
};
